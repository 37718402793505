import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';

import { ApiService } from '../api.service';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface, SwiperScrollbarInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';

declare let $ : any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public secao: string;
  public secaoAtiva: number;
  public id: string;

  public videosConteudo: any;
  public videosDisabled: boolean = false;
  public videosConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    keyboard: false,
    mousewheel: false,
    scrollbar: false,
    navigation: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    spaceBetween: 30,
    breakpoints: {
      576: {
        spaceBetween: 200
      },
    },
    centeredSlides: true,
    effect: 'coverflow',
    coverflowEffect: {
      rotate: 0,
      stretch: 0,
      depth: 120,
      modifier: 1,
      slideShadows: false,
    },
    hashNavigation: {
      watchState: true,
    },
  };
  @ViewChild(SwiperComponent, { static: false }) videosComponentRef?: SwiperComponent;
  @ViewChild(SwiperDirective, { static: false }) videosDirectiveRef?: SwiperDirective;

  public linhadotempoProgresso: string = '0%';
  public linhadotempoConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    effect: 'fade',
    fadeEffect: { crossFade: true },
    navigation: {
      nextEl: '.swiper-nav-button-next',
      prevEl: '.swiper-nav-button-prev',
    },
    keyboard: false,
    mousewheel: false,
    scrollbar: false,
    hashNavigation: {
      watchState: true,
    },
  };
  @ViewChild(SwiperComponent, { static: false }) linhadotempoComponentRef?: SwiperComponent;
  linhadotempo$: any;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public api: ApiService,
    private sanitizer: DomSanitizer,
    private titleService: Title
  ) { }

  ngOnInit() {
    // Obtém ID do vídeo, se declarada
    this.route.params.subscribe(
      params => {
        this.secao = params.secao
        this.api.videoId = ''

        // Salva dados do cabecalho
        this.api.getHeader().subscribe(data => {
          this.api.header = data

          if (undefined == this.secao) {
            this.secaoAtiva = 1
            if (window.location.hash.substring(1) != '') {
              this.api.videoId = window.location.hash.substring(1)
            }
          } else if (this.api.stringToSlug(this.api.header.rows[0]['area-2_br']) == this.secao) {
            this.secaoAtiva = 2
          } else if (this.api.stringToSlug(this.api.header.rows[0]['area-1_br']) == this.secao) {
            this.secaoAtiva = 3
          } else {
            this.router.navigate([''])
          }
        })
      }
    )

    // Ao clicar no botão de próximo
    $(document)
    .on('click', '#SdF_Swiper_Videos .swiper-button-next', () => {
      if (this.videosDirectiveRef) {
        var index = this.videosDirectiveRef.getIndex()

        if (index == this.api.colecao.playlist.length) {
          this.irParaVideo(0)
        } else {
          this.irParaVideo(index + 1)
        }
      }
    })

    // Obtém dados da home
    this.api.getHome().subscribe(
      data => {
        // Salva dados para variável do componente
        this.api.home = data

        // Se não tiver ID de vídeo, apenas manter o primeiro slide
        if (!this.api.videoId)
          this.setVideo(0)
      },
      error => {}
    );

    // Obtém dados de vídeos
    this.api.getVideos().subscribe(
      data => {
        // Salva dados para variável do componente
        this.api.videos = data

        // Obtém coleção
        this.api.fetchColecao()

        // Se ID de vídeo está declarada, deslizar
        if (this.api.videoId) {
          this.api.fetchVideo()
          this.irParaVideo(this.api.videoIndex)
        // Se não estiver, apenas manter o primeiro slide
        } else {
          this.setVideo(0)
        }
      },
      error => {},
      () => {}
    );

    // Obtém dados da linha do tempo
    this.api.getLinhaDoTempo().subscribe(
      data => {
        // Salva dados para variável do componente
        this.linhadotempo$ = data
        this.api.linhadotempo = this.linhadotempo$.rows
        this.setProgresso(0)
        this.linhadotempoConfig.pagination = {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: (index, className) => {
            return '<span class="' + className + '"><span class="swiper-pagination-bullet-label">' + this.api.linhadotempo[index].ano_br + '</span></span>';
          },
        }

        // Se ID de linha do tempo está declarada, deslizar
        if (window.location.hash.substring(1) != '') {
          this.api.linhadotempoId = window.location.hash.substring(1)
          this.irParaLinhadotempo()
        }

      },
      error => {},
      () => {}
    )

    // Obtém dados de seo
    this.api.getSeo().subscribe(
      data => {
        this.api.seo = data
        this.titleService.setTitle(this.api.seo.rows[0]['titulo-do-projeto_br'])
      }
    )
  }

  getImageField() {
    let obj = this.api.home.columns.find(o => o.template === 'image');

    if (undefined !== obj)
      return obj.field

    return false
  }

  getVideoUrl(playerKey, fileId) {
    return 'https://fast.player.liquidplatform.com/pApiv2/embed/' + playerKey + '/' + fileId
  }

  getLinhadotempoVideoUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  setVideo(index: number) {
    if (0 == index) {
      // Checa se conteúdo da home está definido
      if (undefined == this.api.home) {
        return
      }

      this.videosConteudo = {
        id: '',
        botao: false,
        titulo: this.api.home.rows[0].titulo,
        subscribetitulo: '',
        descricao: this.api.home.rows[0].descricao,
      }
    } else {
      // Checa se coleção está definida
      if (undefined == this.api.colecao)
        return false

      // Checa se playlist está definida
      if (undefined == this.api.colecao.playlist)
        return false

      // Checa se vídeo está na playlist
      if (undefined == this.api.colecao.playlist[index - 1])
        return false

      var video = this.api.colecao.playlist[index - 1]
      this.api.videoId = video.id
      this.api.fetchVideo()

      $('#SdF_Swiper_Videos iframe').attr('src', '')
      $('#SdF_Swiper_Videos iframe').eq(index - 1).attr('src', this.getVideoUrl(video._data.playerKey, video._data.fileId))

      // Obtém dados
      this.videosConteudo = {
        id: video.id,
        botao: true,
        titulo: video._data.translatedContent.title,
        subtitulo: video._data.translatedContent.shortDescription,
        descricao: video._data.translatedContent.description,
      }
    }
    this.checkNext(index)
  }

  initVideo(event) {
    this.checkNext(event.activeIndex)
    this.setVideo(event.activeIndex)
  }

  irParaVideo(index: number) {
    if (this.videosDirectiveRef) {
      this.videosDirectiveRef.setIndex(index)
      this.videosDirectiveRef.update()
    }
    this.setVideo(index)

  }

  irParaLinhadotempo() {
    // Checa se linha do tempo está definida
    if (undefined == this.api.linhadotempo)
      return false

    // Checa se slide está definido
    if (undefined == this.api.linhadotempoId || '' == this.api.linhadotempoId)
      return false

    let index = this.api.linhadotempo.findIndex(o => o.id == this.api.linhadotempoId);

    if (index > 0 && this.linhadotempoComponentRef && this.linhadotempoComponentRef.directiveRef) {
      this.linhadotempoComponentRef.directiveRef.setIndex(index)
      window.location.hash = '#' + this.api.linhadotempoId
    }
  }

  checkNext(index: number) {
    var $next = $('.swiper-button-next')

    if (0 == index) {
      $next.removeClass('proximo')
      $next.removeClass('voltar')
    } else if (index == this.api.colecao.playlist.length) {
      $next.removeClass('proximo')
      $next.addClass('voltar')
    } else {
      $next.addClass('proximo')
      $next.removeClass('voltar')
    }
  }

  setProgresso(index: number) {
    if ((0 == index || undefined == index) && undefined !== this.api.linhadotempo) {
      index = this.api.linhadotempo.findIndex(o => o.id == this.api.linhadotempoId);
    }

    if (undefined == index) {
      index = 0
    }

    var slides = 0;

    if (undefined !== this.api.linhadotempo)
      slides = this.api.linhadotempo.length

    var percentage = 0;

    if (slides > 0)
      percentage = Math.round(((index + 1)/slides)*100)

    this.linhadotempoProgresso = percentage + '%';
  }
}
