<div class="SdF--conteudo--compartilhamento d-flex d-sm-none">
  <a href="https://www.facebook.com/sharer/sharer.php?u={{api.getUrl()}}" target="_blank"><i class="fab fa-facebook-f"></i></a> |
  <a href="https://twitter.com/home?status={{api.getUrl()}}" target="_blank"><i class="fab fa-twitter"></i></a> |
  <a href="https://api.whatsapp.com/send?text={{api.getUrl()}}" target="_blank"><i class="fab fa-whatsapp"></i></a> |
  <a href="mailto:info@example.com?&subject=&body={{api.getUrl()}}" target="_blank"><i class="far fa-envelope"></i></a>
</div>

<div class="SdF--compartilhamento d-none d-sm-flex">
	<a href="#" class="SdF--compartilhamento--botao" data-toggle="collapse" data-target="#SdF_Compartilhar" aria-controls="#SdF_Videos" aria-expanded="false"></a>
	<a href="//sesc.digital" target="_blank">
		<img src="assets/logo.svg" alt="Sesc Digital" width="86">
	</a>
</div>
