import { Component, OnInit } from '@angular/core';

import { ApiService } from '../api.service';

@Component({
  selector: 'app-compartilhar',
  templateUrl: './compartilhar.component.html',
  styleUrls: ['./compartilhar.component.scss']
})
export class CompartilharComponent implements OnInit {

  constructor(
    public api: ApiService,
  ) { }

  ngOnInit(): void {
  }

}
