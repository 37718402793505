import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: ':secao', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
  	anchorScrolling: 'enabled',
	})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
