<div *ngIf="secaoAtiva == 1" id="SdF_Videos_Secao">
  <div *ngIf="api.videos && api.colecao">
    <div *ngIf="api.colecao.playlist" class="swiper-container" [swiper]="videosConfig" (indexChange)="setVideo($event)" id="SdF_Swiper_Videos" (init)="initVideo($event)">
      <div class="swiper-button-next"></div>
      <div class="swiper-wrapper">
        <div class="swiper-slide swiper-slide-first" data-hash="" (click)="irParaVideo(0)">
          <div class="SdF--videos--thumbnail" [ngStyle]="{'background-image': 'url(' + api.home.rows[0][getImageField() + '-url'] + ')'}"></div>
        </div>

        <div *ngFor="let item of api.colecao.playlist; let i = index" class="swiper-slide" [attr.data-hash]="item.id" (click)="irParaVideo(i + 1)">
          <div class="SdF--videos--thumbnail" [ngStyle]="{'background-image': 'url(' + item._data.thumbnail + ')'}">
            <iframe [src]=""></iframe>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>

  <div class="container SdF--header" [className]="'container SdF--header' + (!videosConteudo.botao  ? ' __Home' : '') "*ngIf="videosConteudo">
    <div class="SdF--container">
      <div class="d-flex justify-content-between">
        <div class="d-flex flex-column">
          <h2 [innerHtml]="videosConteudo.titulo" [className]="'SdF--conteudo--titulo' + (!videosConteudo.botao  ? ' __Home' : '') "></h2>
          <div class="SdF--conteudo--subtitulo" [innerHtml]="videosConteudo.subtitulo"></div>
        </div>
        <div class="d-none d-sm-inline-block">
          <a class="SdF--videos--botao  float-right" *ngIf="videosConteudo.botao" [routerLink]="['/' + api.stringToSlug(api.header.rows[0]['area-1_br'])]">Ver todos os vídeos <i class="fas fa-chevron-right ml-3"></i></a>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="videosConteudo" [className]="'SdF--conteudo' + (!videosConteudo.botao  ? ' __Home' : '') ">
    <div class="container">
      <div class="SdF--container">
        <div class="text-center">
          <app-compartilhar></app-compartilhar>
          <a class="SdF--videos--botao mb-5 d-inline-block d-sm-none" *ngIf="videosConteudo.botao"  href="#" data-toggle="collapse" data-target="#SdF_Videos" aria-controls="#SdF_Videos" aria-expanded="false">Ver todos os vídeos <i class="fas fa-chevron-right ml-3"></i></a>
        </div>

        <div [innerHtml]="videosConteudo.descricao"></div>
      </div>
    </div>
  </div>
</div>

<div id="SdF_Swiper_LinhaDoTempo" *ngIf="secaoAtiva == 2">
  <div class="swiper-nav">
    <a class="swiper-nav-button swiper-nav-button-prev"><i class="fas fa-chevron-left"></i></a>
    <a class="swiper-nav-button swiper-nav-button-next"><i class="fas fa-chevron-right"></i></a>
  </div>

  <swiper [config]="linhadotempoConfig" class="swiper-container" (indexChange)="setProgresso($event)" (init)="setProgresso(0)">
    <div class="swiper-progress">
      <div class="swiper-progress-track">
        <div class="swiper-progress-bar" [ngStyle]="{'width': linhadotempoProgresso}"></div>
      </div>
      <div class="swiper-progress-text" [innerHtml]="linhadotempoProgresso"></div>
    </div>
    <div *ngFor="let item of api.linhadotempo" class="SdF--linhadotempo" [attr.data-hash]="item.id">
      <div class="SdF--linhadotempo--thumbnail" [ngStyle]="{'background-image': 'url(' + item['imagem-url'] + ')'}">
        <iframe [src]="getLinhadotempoVideoUrl(item.video_br)" *ngIf="item.video_br"></iframe>
      </div>
      <div class="container h-100">
        <div class="SdF--linhadotempo--conteudo">
          <div class="SdF--header">
            <h2 [innerHtml]="item.titulo_br"></h2>
            <p [innerHtml]="item.subtitulo_br"></p>
            <div class="d-sm-none mt-5">
              <app-compartilhar></app-compartilhar>
            </div>
          </div>
          <div>
            <h3 [innerHtml]="item.ano_br" class="SdF--linhadotempo--ano"></h3>
            <p [innerHtml]="item.descricao"></p>
            <div class="d-none d-sm-block">
              <app-compartilhar></app-compartilhar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </swiper>
</div>

<div class="SdF--compartilhamento--modal collapse" id="SdF_Compartilhar">
  <a class="fechar" href="#" data-toggle="collapse" data-target="#SdF_Compartilhar" aria-controls="#SdF_Compartilhar" aria-expanded="false"><i class="far fa-times-circle fa-2x"></i></a>
  <a href="https://www.facebook.com/sharer/sharer.php?u={{api.getUrl()}}" target="_blank">
    <i class="fab fa-facebook-f"></i>
    Facebook
  </a> |
  <a href="https://twitter.com/home?status={{api.getUrl()}}" target="_blank">
    <i class="fab fa-twitter"></i>
    Twitter
  </a> |
  <a href="https://api.whatsapp.com/send?text={{api.getUrl()}}" target="_blank">
    <i class="fab fa-whatsapp"></i>
    WhatsApp
  </a> |
  <a href="mailto:info@example.com?&subject=&body={{api.getUrl()}}" target="_blank">
    <i class="far fa-envelope"></i>
    Email
  </a>
</div>

<!-- Lista de vídeos -->
<div *ngIf="api.videos && api.colecao && api.colecao.playlist && secaoAtiva == 3" class="SdF--videos" id="SdF_Videos">
  <div class="container">
    <div class="SdF--container">
      <h2 class="SdF--titulo" [innerHtml]="api.header.rows[0]['area-1_br']"></h2>
      <div *ngFor="let item of api.colecao.playlist; let i = index" class="SdF--videos--item">
        <div class="SdF--videos--item--col">
          <div class="SdF--videos--thumbnail" [ngStyle]="{'background-image': 'url(' + item._data.thumbnail + ')'}"></div>
        </div>
        <div class="SdF--videos--item--col">
          <h2 class="SdF--videos--titulo"><a href="#" [routerLink]="['/']" fragment="{{item.id}}" [innerHtml]="item._data.translatedContent.title"></a></h2>
          <div [innerHtml]="item._data.releaseYear" class="SdF--videos--subtitulo"></div>
          <div [innerHtml]="item._data.translatedContent.shortDescription" class="SdF--videos--descricao"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="SdF--footer" [className]="'SdF--footer' + (!videosConteudo.botao  ? ' __Home' : '') " *ngIf="secaoAtiva != 2">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="mb-4">
          <a href="//sesc.digital" target="_blank">
            <img src="assets/logo.svg" alt="Sesc Digital" width="86">
          </a>
        </div>
        <div class="d-none d-sm-block">
          <div>Sesc — Serviço Social do Comércio</div>
          <div class="mb-4">Administração Regional no Estado de São Paulo</div>
          <div class="mb-3">
            <span class="mr-4">Sesc São Paulo por aí:</span>
            <a href="https://twitter.com/sescsp" target="_blank" class="mr-4"><i class="fab fa-twitter"></i></a>
            <a href="https://www.youtube.com/sescsp" target="_blank" class="mr-4"><i class="fab fa-youtube"></i></a>
            <a href="https://www.instagram.com/sescsp" target="_blank" class="mr-4"><i class="fab fa-instagram"></i></a>
            <a href="https://www.facebook.com/sescsp" target="_blank" class="mr-4"><i class="fab fa-facebook-square"></i></a>
            <a href="https://open.spotify.com/user/sescsp" target="_blank" class="mr-4"><i class="fab fa-spotify"></i></a>
            <a href="https://itunes.apple.com/br/curator/sescsp/1445771241" target="_blank"><i class="fab fa-itunes-note"></i></a>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-3">
        <div class="mb-4"><a href="https://www.sescsp.org.br/pt/sobre-o-sesc/" target="_blank">Sobre o Sesc São Paulo</a></div>
        <div class="mb-4"><a href="https://sesc.digital/sobre/o-que-e-o-sesc-digital" target="_blank">O que é o Sesc Digital</a></div>
        <div class="mb-4"><a href="https://www.sescsp.org.br/" target="_blank">Portal do Sesc São Paulo</a></div>
        <div class="mb-4"><a href="https://sesc.digital/sobre/termos-de-uso" target="_blank">Termos de uso</a></div>
      </div>
      <div class="col-12 col-sm-3">
        <div class="mb-4"><a href="https://www.sescsp.org.br/servicos/1_MATRICULESE" target="_blank">Matricule-se</a></div>
        <div class="mb-4"><a href="https://sesc.digital/sobre/politicas-de-privacidade" target="_blank">Políticas de privacidade</a></div>
      </div>
      <div class="col-12 d-sm-none">
        <div class="mt-4">Sesc — Serviço Social do Comércio</div>
        <div class="mb-4">Administração Regional no Estado de São Paulo</div>
        <div class="mb-3">
          <div class="mb-2">Sesc São Paulo por aí:</div>
          <a href="https://twitter.com/sescsp" target="_blank" class="mr-4"><i class="fab fa-lg fa-twitter"></i></a>
          <a href="https://www.youtube.com/sescsp" target="_blank" class="mr-4"><i class="fab fa-lg fa-youtube"></i></a>
          <a href="https://www.instagram.com/sescsp" target="_blank" class="mr-4"><i class="fab fa-lg fa-instagram"></i></a>
          <a href="https://www.facebook.com/sescsp" target="_blank" class="mr-4"><i class="fab fa-lg fa-facebook-square"></i></a>
          <a href="https://open.spotify.com/user/sescsp" target="_blank" class="mr-4"><i class="fab fa-lg fa-spotify"></i></a>
          <a href="https://itunes.apple.com/br/curator/sescsp/1445771241" target="_blank"><i class="fab fa-lg fa-itunes-note"></i></a>
        </div>
      </div>
    </div>
  </div>
</div>
