<!-- Nav -->
<header *ngIf="api.header" class="bg-light">
	<nav class="SdF--nav">
		<div class="container">
			<!-- Título do site -->
			<h1 class="SdF--nav--title mr-auto"><a [routerLink]="['/home']" [innerHtml]="api.header.rows[0]['titulo-do-projeto_br']"></a></h1>

			<!-- Botão menu mobile -->
			<button class="SdF--nav--toggler" type="button" data-toggle="collapse" data-target="#SdF_Menu" aria-controls="SdF_Menu" aria-expanded="false" aria-label="Menu">
				<span class="SdF--nav--toggler--text" id="SdF_Menu_Texto">menu</span>
				<span class="SdF--nav--toggler--icon" id="SdF_Menu_IconOpen"></span>
				<span class="SdF--nav--toggler--icon hidden" id="SdF_Menu_IconClose"></span>
			</button>

			<!-- Links -->
			<div class="collapse navbar-collapse fade SdF--nav--menu" id="SdF_Menu">
				<ul class="navbar-nav SdF--nav--items">
					<li class="SdF--nav--item">
						<a class="SdF--nav--link SdF--nav--link__borda" [routerLink]="['/' + api.stringToSlug(api.header.rows[0]['area-1_br'])]" [routerLinkActive]="['__ativo']" [innerHtml]="api.header.rows[0]['area-1_br']"></a>
					</li>
					<li class="SdF--nav--item">
						<a class="SdF--nav--link" [routerLink]="['/' + api.stringToSlug(api.header.rows[0]['area-2_br'])]" [routerLinkActive]="['__ativo']" [innerHtml]="api.header.rows[0]['area-2_br']"></a>
					</li>
				</ul>

				<!-- Logo -->
				<div class="SdF--nav--logo">
					<a href="//sesc.digital" target="_blank">
						<img src="assets/logo.svg" alt="Sesc Digital" width="86">
					</a>
				</div>
			</div>
		</div>
	</nav>
</header>
