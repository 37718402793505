import { Component, OnInit } from '@angular/core';

import { ApiService } from '../api.service';

declare let $ : any;

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  constructor(
    public api: ApiService,
  ) { }

  ngOnInit() {

    // Altera ícone quando menu é escondido
    $(document)
    .on('hide.bs.collapse', '#SdF_Menu', function() {
      $('#SdF_Menu_Texto').show();
      $('#SdF_Menu_IconClose').hide();
      $('#SdF_Menu_IconOpen').show();
    })

    // Altera ícone quando menu é exibido
    .on('show.bs.collapse', '#SdF_Menu', function() {
      $('#SdF_Menu_Texto').hide();
      $('#SdF_Menu_IconClose').show();
      $('#SdF_Menu_IconOpen').hide();
    })

    // Esconde menu quando um link é clicado no menu
    .on('click', '.SdF--nav--link', function() {
      $('#SdF_Menu').collapse('hide');
    })
  }
}
