import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public colecao: any;
  public header: any;
  public home: any;
  public linhadotempo: any;
  public linhadotempoId: any;
  public video: any;
  public videoId: any;
  public videoIndex: number;
  public videos: any;
  public rodape: any;
  public seo: any;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {}

  getHeader() {
    return this.http.get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/sala-de-festivais/configuraces-do-projeto/list.json')
  }

  getHome() {
    return this.http.get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/sala-de-festivais/capa-home/list.json')
  }

  getLinhaDoTempo() {
    return this.http.get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/sala-de-festivais/linha-do-tempo/list.json')
  }

  getRodape() {
    return this.http.get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/sala-de-festivais/links-rodape/list.json')
  }

  getSeo() {
    return this.http.get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/sala-de-festivais/configuraces-do-projeto/list.json')
  }

  getVideos() {
    return this.http.get('https://cms-amplify-deve635af1e990b4abfbc3415205141cbb6dev-cms.s3.us-west-2.amazonaws.com/public/sescdigital-cms-lite/sala-de-festivais/colecao-de-video/list.json')
  }

  fetchColecao() {
    // Checa se ID da coleção está definido
    if (undefined == this.videos.rows[0]['id-da-colecao'])
      return {}

    // Obtém ID da coleção
    let colecaoId = this.videos.rows[0]['id-da-colecao']

    // Checa se a coleção existe no objeto
    if (undefined == this.videos.rows[0]['id-da-colecao-' + colecaoId])
      return {}

    // Salva coleção
    this.colecao = this.videos.rows[0]['id-da-colecao-' + colecaoId]
  }

  fetchVideo() {
    // Checa se coleção está definida
    if (undefined == this.colecao)
      return false

    // Checa se playlist está definida
    if (undefined == this.colecao.playlist)
      return false

    // Checa se vídeo está definido
    if (undefined == this.videoId)
      return false

    let index = this.colecao.playlist.findIndex(o => o.id == this.videoId);

    // Se não encontrar vídeo, redirecionar para lista de vídeos
    if (undefined === this.colecao.playlist[index]) {
      this.router.navigate(['videos'])
      return false
    }

    // Salva index do vídeo e vídeo
    this.videoIndex = index + 1
    this.video = this.colecao.playlist[index]
  }

  stringToSlug(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  }

  getUrl() {
    return window.location.href
  }
}
